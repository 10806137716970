$cursor: (
	'size': 12px,
	'move-size': 12px,
	'hover-size': 20px,
	'target-size': 132px
);
$moveTime: 0ms;
$transitionTime: 650ms;
$opacityTime: 150ms;
$time: 350ms;
$typeSimple: ease-out;
$type: cubic-bezier(0.25, 0, 0, 1);

body:not(.no-hover) {
	* {
		cursor: none;
	}
}

.custom-cursor {
	overflow-x: hidden;
}
body:not(.loaded),
.unfocus {
	.cursor {
		&-container {
			opacity: 0;
		}
	}
}
body.no-hover {
	#cursor {
		display: none;
	}
}
body.page-404 {
	.cursor {
		&-container {
			opacity: 1;
		}
	}
}

.cursor {
	&-container {
		will-change: transform, clip-path, opacity, width, height;
		transition: $time $type;
	}
	will-change: filter, backdrop-filter;
	transition: $opacityTime $type, transform $moveTime;
	#text-404,
	#arrow,
	#video,
	#drag,
	#view {
		transition: all $time $type, opacity $time $type 50ms;
	}
}

.video-btn,
#cursor {
	p {
		font-size: 12px;
		line-height: 16px;
		font-weight: 600;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		text-align: center;
		padding: 16px;
		filter: invert(1);
	}
}

.cursor {
	@media only screen and (hover: none) {
		visibility: hidden;
		z-index: -1;
		&-container {
			opacity: 0;
		}
	}
	pointer-events: none;
	position: fixed;
	top: 0;
	left: 0;
	border-radius: 50%;
	width: map-get($cursor, 'size');
	height: map-get($cursor, 'size');
	transform: translate(-200%, -200%);
	z-index: 300;
	&,
	&-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	&-container {
		position: relative;
		width: map-get($cursor, 'size');
		height: map-get($cursor, 'size');
		border-radius: 50%;
	}
	svg {
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		fill: inherit;
		circle {
			fill: inherit;
			stroke: none;
		}
		g,
		path {
			fill: none;
			stroke: inherit;
		}
	}

	#arrow {
		transform: translate(28%, 30%);
	}
}

.cursor {
	width: map-get($cursor, 'target-size');
	height: map-get($cursor, 'target-size');
	clip-path: circle(9% at 50% 50%);
	backdrop-filter: invert(0);
	&.pointer:not(.arrow):not(.drag):not(.video):not(.cursor-404) {
		$target-size: map-get($cursor, 'hover-size');
		backdrop-filter: invert(1);
		.cursor-container {
			fill: transparent;
			width: map-get($cursor, 'hover-size');
			height: map-get($cursor, 'hover-size');
		}
	}
	&.arrow,
	&.drag,
	&.video,
	&.cursor-404 {
		clip-path: circle(50% at 50% 50%);
		width: map-get($cursor, 'target-size');
		height: map-get($cursor, 'target-size');
		.cursor-container {
			backdrop-filter: none;
		}
	}
	#arrow,
	#drag,
	#view,
	#video,
	#text-404 {
		position: absolute;
	}
	&:not(.arrow) {
		#arrow {
			opacity: 0;
			transform: scale(0);
		}
	}

	&:not(.drag) {
		#drag {
			transform: scale(0);
		}
	}
	&:not(.view) {
		#view {
			transform: scale(0);
		}
	}
	&:not(.video) {
		#video {
			transform: scale(0);
		}
	}
	&:not(.cursor-404) {
		#text-404 {
			transform: scale(0);
		}
	}
	&.view {
		#drag {
			opacity: 0;
			transform: scale(0);
		}
	}

	// &.move {
	// 	.cursor-container {
	// 		width: map-get($cursor, 'move-size');
	// 		height: map-get($cursor, 'move-size');
	// 	}
	// }
	& {
		$target-size: map-get($cursor, 'target-size');
		&.arrow,
		&.video,
		&.drag,
		&.view,
		&.cursor-404 {
			.cursor-container {
				width: $target-size;
				height: $target-size;
			}
		}
	}
}
