body:not(.no-hover) * {
  cursor: none;
}

.custom-cursor {
  overflow-x: hidden;
}

body:not(.loaded) .cursor-container,
.unfocus .cursor-container {
  opacity: 0;
}

body.no-hover #cursor {
  display: none;
}

body.page-404 .cursor-container {
  opacity: 1;
}

.cursor {
  will-change: filter, backdrop-filter;
  transition: 150ms cubic-bezier(0.25, 0, 0, 1), transform 0ms;
}

.cursor-container {
  will-change: transform, clip-path, opacity, width, height;
  transition: 350ms cubic-bezier(0.25, 0, 0, 1);
}

.cursor #text-404,
.cursor #arrow,
.cursor #video,
.cursor #drag,
.cursor #view {
  transition: all 350ms cubic-bezier(0.25, 0, 0, 1), opacity 350ms cubic-bezier(0.25, 0, 0, 1) 50ms;
}

.video-btn p,
#cursor p {
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;
  padding: 16px;
  filter: invert(1);
}

.cursor {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transform: translate(-200%, -200%);
  z-index: 300;
}

@media only screen and (hover: none) {
  .cursor {
    visibility: hidden;
    z-index: -1;
  }
  .cursor-container {
    opacity: 0;
  }
}

.cursor, .cursor-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cursor-container {
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.cursor svg {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  fill: inherit;
}

.cursor svg circle {
  fill: inherit;
  stroke: none;
}

.cursor svg g,
.cursor svg path {
  fill: none;
  stroke: inherit;
}

.cursor #arrow {
  transform: translate(28%, 30%);
}

.cursor {
  width: 132px;
  height: 132px;
  clip-path: circle(9% at 50% 50%);
  backdrop-filter: invert(0);
}

.cursor.pointer:not(.arrow):not(.drag):not(.video):not(.cursor-404) {
  backdrop-filter: invert(1);
}

.cursor.pointer:not(.arrow):not(.drag):not(.video):not(.cursor-404) .cursor-container {
  fill: transparent;
  width: 20px;
  height: 20px;
}

.cursor.arrow, .cursor.drag, .cursor.video, .cursor.cursor-404 {
  clip-path: circle(50% at 50% 50%);
  width: 132px;
  height: 132px;
}

.cursor.arrow .cursor-container, .cursor.drag .cursor-container, .cursor.video .cursor-container, .cursor.cursor-404 .cursor-container {
  backdrop-filter: none;
}

.cursor #arrow,
.cursor #drag,
.cursor #view,
.cursor #video,
.cursor #text-404 {
  position: absolute;
}

.cursor:not(.arrow) #arrow {
  opacity: 0;
  transform: scale(0);
}

.cursor:not(.drag) #drag {
  transform: scale(0);
}

.cursor:not(.view) #view {
  transform: scale(0);
}

.cursor:not(.video) #video {
  transform: scale(0);
}

.cursor:not(.cursor-404) #text-404 {
  transform: scale(0);
}

.cursor.view #drag {
  opacity: 0;
  transform: scale(0);
}

.cursor.arrow .cursor-container, .cursor.video .cursor-container, .cursor.drag .cursor-container, .cursor.view .cursor-container, .cursor.cursor-404 .cursor-container {
  width: 132px;
  height: 132px;
}
